






import Vue from "vue"

export default Vue.extend({
  props: {
    isVisibleIcon: {
      type: Boolean,
      default: false,
    },
  },
})
