






import Vue from "vue"

export default Vue.extend({
  methods: {
    handleSubmit() {
      this.$emit("submit")
    },
  },
})
