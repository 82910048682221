












































import Vue from "vue"
import FormFieldWithRequiredIcon from "~/components/guideline/form/local/FormFieldWithRequiredIcon.vue"
import FormErrorMessage from "~/components/guideline/form/local/FormErrorMessage.vue"
import FormHintText from "~/components/guideline/form/local/FormHintText.vue"
import color from "~/config/color"

export default Vue.extend({
  components: {
    FormFieldWithRequiredIcon,
    FormErrorMessage,
    FormHintText,
  },
  props: {
    // **注意** : valueは呼び出し元では :value ではなく、 v-model に設定すること。
    // 本コンポーネントは value を v-model に設定することで、値の更新処理を実行するようになっている。
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: [Boolean, String, Number],
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  computed: {
    hideDetail() {
      return !this.hint && !this.errorMessage && !this.counter
    },
    keyColor() {
      return color.keyColor
    },
    dense() {
      return this.$device.isMobile
    },
  },
  methods: {
    /**
     * フォーム右のクリアボタンを押すとnullが入る。
     */
    updateValue(value: string | null): void {
      this.handleInput(value || "")
    },
    trim(): void {
      const newVal = this.value.trim()
      this.handleInput(newVal)
    },
    handleInput(newVal: string) {
      // 呼び出し元の v-model で newVal の値を受け取り、 value が更新される。
      this.$emit("input", newVal)
    },
    handleClickClear() {
      this.$emit("click:clear")
    },
    handleEnter(event: KeyboardEvent) {
      // Enter
      if (event.keyCode === 13) {
        this.$emit("enter")
      }
    },
  },
})
